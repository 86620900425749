import { Doughnut } from 'react-chartjs-2'
import { ArcElement, Tooltip, Chart as Chartjs } from 'chart.js'
import { ReactNode } from 'react'
import { getPriceFromName } from 'state/slices/app/brokers'
import { MetaBroker } from 'state/mock'
import { types } from '@concordia/super-sdk'
import { noteNameToBroker } from 'toolbox/account'
Chartjs.register(ArcElement)
Chartjs.register(Tooltip)

export interface Set {
  label: string
  data: number[]
  backgroundColor: string[]
  hoverOffset: number
}

export interface CData {
  labels: string[]
  datasets: Set[]
}

export interface BalanceChartProps {
  children?: ReactNode
  positions: types.NameBalanceMap
  prices: types.NameBalanceMap
  brokers: MetaBroker[]
}

export const colorMapping = {
  aptos: '#9224FF',
  usdc: '#0fa9a0',
  amapt: '#5886cc',
  stapt: '#FF6CD6',
  thapt: '#b02892',
  sthapt: '#C7CB0D',
  usdcwh: '#BB5A3B',
  usdtwh: '#551AD3',
  usdtlz: '#1C768A',
  weth: '#BE7E00',
  wethwh: '#00BA61',
  thl: '#9B400D',
  wbtc: '#ff7f50'
}

export const colorClassMapping = {
  aptos: 'c-1',
  usdc: 'c-2',
  amapt: 'c-3',
  stapt: 'c-4',
  thapt: 'c-5',
  sthapt: 'c-6',
  usdcwh: 'c-7',
  usdtwh: 'c-8',
  usdtlz: 'c-9',
  weth: 'c-10',
  wethwh: 'c-11',
  thl: 'c-12',
  wbtc: 'c-13'
}

function BalanceChart({ children, positions, prices, brokers }: BalanceChartProps) {
  if (!positions) {
    return
  }
  const balKeys = Object.keys(positions)
  const dataVals = []
  const backgroundColors = [] // Array to hold the colors for the dataset
  const labels = [] // Initialize labels array

  balKeys.forEach((noteName) => {
    const value = positions[noteName] * getPriceFromName(noteName, prices)
    const broker = noteNameToBroker(brokers, noteName)
    const label = broker?.underlyingAsset.name
    labels.push(label) // Add label
    dataVals.push(value) // Add value
    backgroundColors.push(colorMapping[label]) // Assign color based on mapping or default color
  })

  // Sort the dataVals and align the labels and backgroundColors with the sorted data
  const indices = dataVals.map((value, index) => index).sort((a, b) => dataVals[b] - dataVals[a])
  const sortedDataVals = indices.map((index) => dataVals[index])
  const sortedLabels = indices.map((index) => labels[index])
  const sortedBackgroundColors = indices.map((index) => backgroundColors[index])

  const cd: CData = {
    labels: sortedLabels,
    datasets: [
      {
        label: 'Supply Balance (Dollars)',
        data: sortedDataVals,
        backgroundColor: sortedBackgroundColors,
        hoverOffset: 2
      }
    ]
  }

  return (
    <div className="doughnut-chart-container">
      <Doughnut
        data={cd}
        options={{
          animation: { animateRotate: true },
          cutout: 113,
          rotation: 180,
          responsive: true,
          interaction: {
            mode: 'nearest'
          },
          plugins: {
            tooltip: {
              enabled: false,
              position: 'average'
            }
          }
        }}
      />
      {!!children && children}
    </div>
  )
}

export default BalanceChart
